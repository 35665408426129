export const VOLUME_UNITS = {
  OUNCE: 'oz',
  CENTILITRE: 'cl',
  LITRE: 'l',
  MILILITRE: 'ml'
};

export const VOLUME_UNITS_TRANSLATIONS = {
  'en-US': {
    [VOLUME_UNITS.OUNCE]: {
      name: 'Ounce',
      value: VOLUME_UNITS.OUNCE
    },
    [VOLUME_UNITS.CENTILITRE]: {
      name: 'Centilitre',
      value: VOLUME_UNITS.CENTILITRE
    },
    [VOLUME_UNITS.LITRE]: {
      name: 'Litre',
      value: VOLUME_UNITS.LITRE
    },
    [VOLUME_UNITS.MILILITRE]: {
      name: 'Mililitre',
      value: VOLUME_UNITS.MILILITRE
    }
  },
  es: {
    [VOLUME_UNITS.OUNCE]: {
      name: 'Onza',
      value: VOLUME_UNITS.OUNCE
    },
    [VOLUME_UNITS.CENTILITRE]: {
      name: 'Centilitro',
      value: VOLUME_UNITS.CENTILITRE
    },
    [VOLUME_UNITS.LITRE]: {
      name: 'Litro',
      value: VOLUME_UNITS.LITRE
    },
    [VOLUME_UNITS.MILILITRE]: {
      name: 'Mililitro',
      value: VOLUME_UNITS.MILILITRE
    }
  }
};

type VolumeUnit = {
  name: string;
  value: string;
};

export const getVolumeUnitsForLanguage = (language: string): VolumeUnit[] => {
  return Object.values(VOLUME_UNITS_TRANSLATIONS[language as keyof typeof VOLUME_UNITS_TRANSLATIONS] || VOLUME_UNITS_TRANSLATIONS['en-US']);
};

export const DEFAULT_VOLUME_UNIT = VOLUME_UNITS.OUNCE;

const VOLUME_UNITS_PER_OUNCE = {
  [VOLUME_UNITS.OUNCE]: 1,
  [VOLUME_UNITS.CENTILITRE]: 2.95735,
  [VOLUME_UNITS.LITRE]: 0.0295745,
  [VOLUME_UNITS.MILILITRE]: 29.5735
};

export function volumeUnitConverter(volumeUnit = VOLUME_UNITS.OUNCE, sizeOnOunces: number) {
  return VOLUME_UNITS_PER_OUNCE[volumeUnit] * sizeOnOunces;
}
