import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import ChevronDown from '../../../images/chevron-down';

interface InputOption {
  value: string;
  label: React.ReactNode;
  className?: string;
}

interface AutoCompleteProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | boolean;
  options?: InputOption[];
  value?: string;
  onChange?: (value: string) => void;
  containerClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  label,
  error,
  options = [],
  value = '',
  onChange,
  className = '',
  containerClassName = '',
  startIcon,
  endIcon,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => {
    setShowOptions(false);
    setSearchText('');
  }, []);

  const openDropdown = useCallback(() => {
    if (options?.length > 0 && !props.disabled) {
      setShowOptions(true);
    }
  }, [options?.length, props.disabled]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node) && !dropdownRef.current?.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showOptions, closeDropdown]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchText(inputValue);
    onChange?.(inputValue);
    openDropdown();
  };

  const handleOptionClick = (option: InputOption) => {
    if (option) {
      onChange?.(option.value);
      closeDropdown();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    openDropdown();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasValue = value !== undefined && value !== '';
  const showLabel = isFocused || hasValue;

  const displayValue = useMemo(() => {
    if (value === null || value === undefined) return '';

    if (isFocused && showOptions) {
      return searchText || value;
    }

    const selectedOption = options.find((opt) => opt.value === value);
    return selectedOption?.label?.toString() ?? value;
  }, [value, options, isFocused, showOptions, searchText]);

  const filteredOptions = useMemo(() => {
    if (!showOptions) return [];

    const searchValue = (searchText || value).toLowerCase();
    if (!searchValue) return options;

    return options.filter((option) => {
      const optionStr = `${option.label} ${option.value}`.toLowerCase();
      return optionStr.includes(searchValue);
    });
  }, [options, showOptions, searchText, value]);

  const inputClassName = `
    peer w-full px-4 py-3
    border rounded-[4px] 
    outline-none 
    focus:ring-0
    text-base 
    leading-6
    placeholder-transparent 
    resize-none
    cursor-pointer
    bg-white
    ${
      error ? 'text-red' : !props.disabled ? 'text-blackish border-light-grey hover:border-dark-grey' : 'text-light-grey border-accent-grey'
    }
    ${error ? 'border-red focus:border-red' : !!isFocused && 'border-hover-green focus:border-hover-green'}
    transition-all duration-200 ease-in-out
    ${className}
  `;

  const labelClassName = `
    absolute left-3 px-1
    pointer-events-none
    text-light-blackish
    whitespace-nowrap
    bg-white
    transform
    -translate-y-1/2
    transition-all duration-200 ease-in-out
    peer-placeholder-shown:text-base
    peer-placeholder-shown:top-1/2
    peer-placeholder-shown:translate-y-1/2
    peer-placeholder-shown:px-0
    peer-placeholder-shown:left-4
    [div:focus-within>&]:top-0
    [div:focus-within>&]:left-3
    [div:focus-within>&]:px-1
    [div:focus-within>&]:text-sm
    ${hasValue ? 'top-0 text-sm' : 'top-1/2 text-base'}
    ${error && 'text-red [div:focus-within>&]:text-red'}
  `;

  return (
    <div ref={containerRef} className={`relative w-full ${containerClassName}`}>
      <div className="relative">
        <div className="relative flex items-center">
          {startIcon && <div className="absolute left-4">{startIcon}</div>}
          <input
            ref={inputRef}
            type="text"
            value={displayValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={!showLabel ? label : ''}
            className={`
              ${inputClassName}
              h-[56px]
              ${startIcon ? 'pl-12' : ''}
              ${endIcon ? 'pr-12' : ''}
            `}
            {...props}
          />
          <div className="absolute right-4 flex items-center gap-2 pointer-events-none">
            <span
              className={`w-4 h-4 ${
                !props.disabled ? 'text-dark-grey' : 'text-light-grey'
              } transition-transform duration-200 inline-block ${showOptions ? 'rotate-180' : ''}`}>
              <ChevronDown className="w-4 h-4" />
            </span>
            {endIcon && <div className="flex items-center">{endIcon}</div>}
          </div>
        </div>

        {label && <label className={labelClassName}>{label}</label>}
        {typeof error === 'string' && <p className="mt-1 text-xs text-red-500">{error}</p>}

        {filteredOptions.length > 0 &&
          inputRef.current &&
          createPortal(
            <div
              ref={dropdownRef}
              style={{
                top: inputRef.current?.getBoundingClientRect().bottom + window.scrollY,
                left: inputRef.current?.getBoundingClientRect().left + window.scrollX,
                width: inputRef.current?.offsetWidth
              }}
              className={`bg-white border absolute z-[9999] border-light-grey rounded text-blackish overflow-auto transition-opacity duration-500 ${
                showOptions ? 'opacity-100 visible max-h-60 w-full' : 'opacity-0 invisible h-0 w-0'
              }`}>
              {filteredOptions.map((option, index) => (
                <div
                  key={index}
                  data-value={option.value}
                  className={`px-4 py-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ${
                    option.value === value ? 'bg-accent-grey' : ''
                  } ${option.className ?? 'font-public-sans'}`}
                  onMouseDown={() => handleOptionClick(option)}>
                  {option.label}
                </div>
              ))}
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};
