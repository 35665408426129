interface PageDensityMediumProps {
  className?: string;
}

const PageDensityMedium = ({ className }: PageDensityMediumProps) => (
  <svg width="128" height="126" viewBox="0 0 128 126" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="32" height="12.4" fill="currentColor" />
    <rect y="28.4" width="32" height="12.4" fill="currentColor" />
    <rect y="56.8" width="32" height="12.4" fill="currentColor" />
    <rect y="85.2" width="32" height="12.4" fill="currentColor" />
    <rect y="113.6" width="32" height="12.4" fill="currentColor" />
    <rect x="48" width="32" height="12.4" fill="currentColor" />
    <rect x="48" y="28.4" width="32" height="12.4" fill="currentColor" />
    <rect x="48" y="56.8" width="32" height="12.4" fill="currentColor" />
    <rect x="48" y="85.2" width="32" height="12.4" fill="currentColor" />
    <rect x="48" y="113.6" width="32" height="12.4" fill="currentColor" />
    <rect x="96" width="32" height="12.4" fill="currentColor" />
    <rect x="96" y="28.4" width="32" height="12.4" fill="currentColor" />
    <rect x="96" y="56.8" width="32" height="12.4" fill="currentColor" />
    <rect x="96" y="85.2" width="32" height="12.4" fill="currentColor" />
    <rect x="96" y="113.6" width="32" height="12.4" fill="currentColor" />
  </svg>
);

export default PageDensityMedium;
