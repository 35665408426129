interface Props {
  className?: string;
}

const ImageIcon = ({ className }: Props) => (
  <svg className={className} width="60" height="60" viewBox="0 0 60 60">
    <path
      d="M51.125 9.375H9.875C8.88044 9.375 7.92661 9.77009 7.22335 10.4733C6.52009 11.1766 6.125 12.1304 6.125 13.125V46.875C6.125 47.8696 6.52009 48.8234 7.22335 49.5266C7.92661 50.2299 8.88044 50.625 9.875 50.625H51.125C52.1196 50.625 53.0734 50.2299 53.7766 49.5266C54.4799 48.8234 54.875 47.8696 54.875 46.875V13.125C54.875 12.1304 54.4799 11.1766 53.7766 10.4733C53.0734 9.77009 52.1196 9.375 51.125 9.375ZM51.125 13.125V37.207L45.0148 31.0992C44.6666 30.7509 44.2532 30.4746 43.7982 30.2861C43.3431 30.0976 42.8554 30.0006 42.3629 30.0006C41.8704 30.0006 41.3827 30.0976 40.9276 30.2861C40.4726 30.4746 40.0592 30.7509 39.7109 31.0992L35.0234 35.7867L24.7109 25.4742C24.0077 24.7715 23.0543 24.3767 22.0602 24.3767C21.066 24.3767 20.1126 24.7715 19.4094 25.4742L9.875 35.0086V13.125H51.125ZM9.875 40.3125L22.0625 28.125L40.8125 46.875H9.875V40.3125ZM51.125 46.875H46.1164L37.6789 38.4375L42.3664 33.75L51.125 42.5109V46.875ZM34.25 23.4375C34.25 22.8812 34.415 22.3375 34.724 21.875C35.033 21.4124 35.4723 21.052 35.9862 20.8391C36.5001 20.6262 37.0656 20.5705 37.6112 20.679C38.1568 20.7876 38.6579 21.0554 39.0512 21.4488C39.4446 21.8421 39.7124 22.3432 39.821 22.8888C39.9295 23.4344 39.8738 23.9999 39.6609 24.5138C39.448 25.0277 39.0876 25.467 38.625 25.776C38.1625 26.0851 37.6188 26.25 37.0625 26.25C36.3166 26.25 35.6012 25.9537 35.0738 25.4262C34.5463 24.8988 34.25 24.1834 34.25 23.4375Z"
      fill="currentColor"
    />
  </svg>
);

export default ImageIcon;
