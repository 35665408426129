import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../../components/CustomLink';
import FileUpload from '../../../../components/FileUpload';
import { Checkbox } from '../../../../components/shared/Checkbox';
import { ColorPicker } from '../../../../components/shared/ColorPicker';
import { FontPicker } from '../../../../components/shared/FontPicker';
import { Input, Select } from '../../../../components/shared/inputs';
import QuestionIcon from '../../../../images/icon-question';
import { TAP_ORIENTATION } from '../../../../util/enums';
import { MAX_SCREEN_IMAGE_SIZE_BYTES } from '../../../../util/files';
import { HelperPopups } from './HelperPopups';
import { useHelperPopups } from './HelperPopups/useHelperPopups';
import PageColumnStyle1 from '../../../../images/page-column-style-1';
import PageColumnStyle2 from '../../../../images/page-column-style-2';
import PageColumnStyle3 from '../../../../images/page-column-style-3';
import PageTapOrderHorizontal from '../../../../images/page-tap-order-horizontal';
import PageTapOrderVertical from '../../../../images/page-tap-order-vertical';
import PageDensityLow from '../../../../images/page-density-low';
import PageDensityMedium from '../../../../images/page-density-medium';
import PageDensityHigh from '../../../../images/page-density-high';
import PremiumFeatureDialog from '../../../../components/PremiumFeatureDialog';
import IconPremium from '../../../../images/icon-premium.svg';

import { MDXEditor, toolbarPlugin, BoldItalicUnderlineToggles } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

export const PageStyleCustom = (props: Props) => {
  const { t } = useTranslation();

  const helperPopups = useHelperPopups();
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = React.useState(false);

  return (
    <>
      <HelperPopups {...helperPopups} />

      <div className="grid grid-cols-12 gap-4">
        <h3 className="text-dark-grey uppercase font-bold col-span-12 flex items-center gap-1">
          <span>{t('PageEditForm.Size')}</span>
        </h3>
        <Select
          variant="card"
          cardClassName="w-44 h-44"
          label={t('PageEditForm.Layout')}
          value={props.menuType}
          options={[
            { value: 'dense', label: t('PageEditForm.Dense'), children: <PageDensityHigh /> },
            { value: 'middle', label: t('PageEditForm.MiddleGround'), children: <PageDensityMedium /> },
            { value: 'normal', label: t('PageEditForm.Relaxed'), children: <PageDensityLow /> }
          ]}
          onChange={(value) => {
            props.setMenuType(value as string);
          }}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Select
          variant="card"
          cardClassName="w-56 h-16"
          label={t('PageEditForm.TextSize')}
          value={props.textSize}
          hideCardLabel
          options={[
            { value: 'condensed', children: <p className="text-[.8rem]">{t('PageEditForm.Small')}</p>, label: t('PageEditForm.Small') },
            { value: 'medium', children: <p className="text-base">{t('PageEditForm.Medium')}</p>, label: t('PageEditForm.Medium') },
            { value: 'normal', children: <p className="text-[1.1rem]">{t('PageEditForm.Large')}</p>, label: t('PageEditForm.Large') },
            { value: 'xl', children: <p className="text-[1.3rem]">{t('PageEditForm.ExtraLarge')}</p>, label: t('PageEditForm.ExtraLarge') },
            {
              value: 'xl2',
              children: <p className="text-[1.5rem]">{t('PageEditForm.ExtraExtraLarge')}</p>,
              label: t('PageEditForm.ExtraExtraLarge')
            },
            { value: 'xl3', children: <p className="text-[1.7rem]">{t('PageEditForm.SuperSize')}</p>, label: t('PageEditForm.SuperSize') },
            { value: 'xxl', children: <p className="text-[1.8rem]">{t('PageEditForm.HulkMode')}</p>, label: t('PageEditForm.HulkMode') }
          ]}
          onChange={(value) => props.setTextSize(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
          cardContentClassName="w-full"
        />
        <Select
          variant="card"
          cardClassName="w-44 h-44"
          label={t('PageEditForm.Columns')}
          value={props.columns}
          options={[
            { value: 1, label: '1 Column', children: <PageColumnStyle1 /> },
            { value: 2, label: '2 Columns', children: <PageColumnStyle2 /> },
            { value: 3, label: '3 Columns', children: <PageColumnStyle3 /> }
          ]}
          onChange={(value) => props.setColumns(Number(value))}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Select
          variant="card"
          cardClassName="w-44 h-44"
          label={t('PageEditForm.TapsOrientation')}
          value={props.tapsOrientation}
          options={[
            { value: TAP_ORIENTATION.LEFT_TO_RIGHT, label: t('PageEditForm.LeftToRight'), children: <PageTapOrderHorizontal /> },
            { value: TAP_ORIENTATION.TOP_TO_BOTTOM, label: t('PageEditForm.TopToBottom'), children: <PageTapOrderVertical /> }
          ]}
          onChange={(value) => props.setTapsOrientation(value as TAP_ORIENTATION)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
      </div>

      <div className="grid grid-cols-12 gap-2 mt-10">
        <h3 className="col-span-12 font-bold text-dark-grey uppercase flex items-center gap-1">
          <span>{t('PageEditForm.Colors')}</span>
          <button onClick={() => helperPopups.openColorsHelper()}>
            <QuestionIcon className="text-light-grey" />
          </button>
        </h3>
        <ColorPicker
          label={t('PageEditForm.BackgroundColor')}
          value={props.backgroundColor}
          onChange={(color) => props.setBackgroundColor(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
        <ColorPicker
          label={t('PageEditForm.TextColor')}
          value={props.foregroundColor}
          onChange={(color) => props.setForegroundColor(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
        <ColorPicker
          label={t('PageEditForm.AltTextColor')}
          value={props.foregroundColor2}
          onChange={(color) => props.setForegroundColor2(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-10">
        <h3 className="col-span-12 md:col-span-6 font-bold text-dark-grey uppercase">{t('PageEditForm.Fonts')}</h3>

        <h3 className="hidden md:block col-span-6 font-bold text-dark-grey uppercase">{t('PageEditForm.Partners')}</h3>

        <FontPicker
          value={props.primaryFont}
          label={t('PageEditForm.Primary')}
          onChange={(font) => props.setPrimaryFont(font)}
          containerClassName="col-span-12 md:col-span-3"
        />
        <FontPicker
          value={props.secondaryFont}
          label={t('PageEditForm.Secondary')}
          onChange={(font) => props.setSecondaryFont(font)}
          containerClassName="col-span-12 md:col-span-3"
        />

        <h3 className="md:hidden col-span-12 font-bold text-dark-grey uppercase mt-6">{t('PageEditForm.Partners')}</h3>
        <Select
          label={t('PageEditForm.KegMonitoring')}
          value={props.kegMonitoringPartner}
          options={[
            { value: 'none', label: t('PageEditForm.None') },
            { value: 'kegtron', label: t('PageEditForm.Kegtron') }
          ]}
          onChange={(value) => props.setKegMonitoringPartner(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Input
          label={t('PageEditForm.KegtronApiKey')}
          value={props.kegMonitoringApiKey}
          onChange={(value) => props.setKegMonitoringApiKey(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
          disabled={props.kegMonitoringPartner !== 'kegtron'}
        />
      </div>
      <div className="flex flex-col mt-10">
        <div className="flex flex-nowrap gap-1 text-dark-grey items-baseline mb-4">
          <h3 className="font-bold uppercase">{t('PageEditForm.Options')}</h3>
          <span className="text-xs">{t('PageEditForm.OptionsSubtitle')}</span>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-0 items-start">
          <div className="grid grid-cols-2 gap-5 items-start w-full ">
            <Checkbox
              label={t('PageEditForm.ShowPageName')}
              checked={props.showHeading}
              onChange={(checked) => props.setShowHeading(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowServings')}
              checked={props.showServings}
              onChange={(checked) => props.setShowServings(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowTapNumber')}
              checked={props.showTapNumber}
              onChange={(checked) => props.setShowTapNumber(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowServingSize')}
              checked={props.showServingSize}
              onChange={(checked) => props.setShowServingSize(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowBreweryName')}
              checked={!props.hideBeverageSourceName}
              onChange={(checked) => props.setHideBeverageSourceName(!checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowServingCurrency')}
              checked={props.showServingCurrency}
              onChange={(checked) => props.setShowServingCurrency(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ReverseBreweryAndName')}
              checked={props.reverseBreweryAndName}
              onChange={(checked) => props.setReverseBreweryAndName(checked)}
              className="col-span-2"
            />
          </div>
          <div className="grid grid-cols-2 gap-5 items-start justify-start w-full">
            <div className="flex gap-1">
              <Checkbox
                label={t('PageEditForm.ShowStripes')}
                checked={props.showStripes}
                onChange={(checked) => props.setShowStripes(checked)}
              />
              <button onClick={() => helperPopups.openOptionsHelper()}>
                <QuestionIcon className="text-light-grey" />
              </button>
            </div>
            <Checkbox
              label={t('PageEditForm.ShowLocation')}
              checked={props.showLocation}
              onChange={(checked) => props.setShowLocation(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowTapsDescription')}
              checked={props.showTapsDescription}
              onChange={(checked) => props.setShowTapsDescription(checked)}
            />
            <Select
              label={t('PageEditForm.BeverageImages')}
              value={props.beverageImageSize}
              options={[
                { value: 'none', label: t('PageEditForm.None') },
                { value: 'small', label: t('PageEditForm.SmallRound') },
                { value: 'small_square', label: t('PageEditForm.SmallSquare') },
                { value: 'large', label: t('PageEditForm.LargeRound') },
                { value: 'large_square', label: t('PageEditForm.LargeSquare') }
              ]}
              onChange={(value) => {
                props.setBeverageImageSize(value as string);
                props.setShowBeverageImage(value !== 'none');
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <h3 className="col-span-12 mt-8 font-bold text-dark-grey uppercase">{t('PageEditForm.BackgroundImage')}</h3>
        <div className="col-span-12">
          <FileUpload
            id={'background_image_selection'}
            onFileSelected={props.handleBackgroundImageSelection}
            maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES}
          />
        </div>

        {(props.backgroundImageUrl || props.backgroundImagePreviewUrl) && (
          <Checkbox
            label={t('PageEditForm.AutoDarken')}
            checked={props.showBackgroundImageHelper}
            onChange={(checked) => props.setShowBackgroundImageHelper(checked)}
            className="col-span-12 md:col-span-6"
          />
        )}

        <div className="col-span-12">
          {props.backgroundImagePreviewUrl && (
            <img
              className={`w-full mt-0 sm:h-[250px] sm:w-auto ${!!props.showBackgroundImageHelper && 'brightness(70%)'}`}
              src={props.backgroundImagePreviewUrl}
              alt="Preview"
            />
          )}
          {!props.backgroundImagePreviewUrl && props.backgroundImageUrl && (
            <img
              className={`w-full mt-0 sm:h-[250px] sm:w-auto ${!!props.showBackgroundImageHelper && 'brightness(70%)'}`}
              src={props.backgroundImageUrl}
              alt="Custom"
            />
          )}
          {(props.backgroundImageUrl || props.backgroundImagePreviewUrl) && (
            <p>
              <CustomLink onClick={props.removeBackgroundImage}>{t('PageEditForm.RemoveBackgroundImage')}</CustomLink>
            </p>
          )}
        </div>
      </div>

      {props.premium ? (
        <div className="flex flex-col mt-5">
          <div className="flex flex-nowrap gap-1 text-dark-grey items-baseline mb-4">
            <h3 className="font-bold uppercase">{t('PageEditForm.Footer')}</h3>
            <span className="text-xs">{t('PageEditForm.FooterSubtitle')}</span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 ">
            <div className="flex flex-col gap-4">
              <Checkbox label={t('PageEditForm.ShowLogo')} checked={props.showLogo} onChange={(checked) => props.setShowLogo(checked)} />
              <Checkbox
                label={t('PageEditForm.ShowFooterText')}
                checked={props.showFooterText}
                onChange={(checked) => props.setShowFooterText(checked)}
              />
            </div>
            <div className="col-span-2 md:col-span-4">
              <div className="relative w-full">
                <div className="relative">
                  <MDXEditor
                    markdown={props.footerText}
                    onChange={(content) => props.setFooterText(content)}
                    placeholder={<p className="text-light-grey">{t('PageEditForm.FooterText')}</p>}
                    plugins={[
                      toolbarPlugin({
                        toolbarContents: () => <BoldItalicUnderlineToggles />
                      })
                    ]}
                    contentEditableClassName="
                      peer w-full px-4 py-3
                      border rounded-[4px] 
                      outline-none 
                      focus:ring-0
                      text-base 
                      leading-6
                      min-h-[10px]
                      text-blackish border-light-grey hover:border-dark-grey
                      focus:border-hover-green
                      transition-all duration-200 ease-in-out
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setShowPremiumFeatureDialog(true)} className="relative cursor-pointer flex flex-col items-center justify-center border-accent-grey rounded-md border-2 border-dashed p-6 mt-5">
        <div className="flex flex-nowrap gap-1 text-dark-grey items-baseline">
          <h3 className="font-bold uppercase">{t('PageEditForm.Footer')}</h3>
        </div>
        <img alt="Icon Premium Feature" src={IconPremium} width={32} height={32} className="absolute top-2 right-2 rotate-[30deg]" />
        <span className="text-golden text-sm font-bold uppercase">{t('PageEditForm.FooterPremiumTitle')}</span>
        <p className="text-light-grey text-center text-sm mt-1"><span className="text-hover-green">{t('PageEditForm.FooterPremiumDescriptionHighlight')}</span>{t('PageEditForm.FooterPremiumDescription')}</p>
  </div>
      )}
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureDescription={t('PremiumFeatureDialog.Features.MultiLocation')}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </>
  );
};

interface Props {
  menuType: string;
  setMenuType: (value: string) => void;
  columns: number;
  setColumns: (value: number) => void;
  primaryFont: string;
  setPrimaryFont: (value: string) => void;
  secondaryFont: string;
  setSecondaryFont: (value: string) => void;
  backgroundColor: string;
  setBackgroundColor: (value: string) => void;
  foregroundColor: string;
  setForegroundColor: (value: string) => void;
  foregroundColor2: string;
  setForegroundColor2: (value: string) => void;
  textSize: string;
  setTextSize: (value: string) => void;
  tapsOrientation: string;
  setTapsOrientation: (value: string) => void;
  setReverseBreweryAndName: (value: boolean) => void;
  kegMonitoringPartner: string;
  setKegMonitoringPartner: (value: string) => void;
  kegMonitoringApiKey: string;
  setKegMonitoringApiKey: (value: string) => void;
  showHeading: boolean;
  setShowHeading: (value: boolean) => void;
  showTapNumber: boolean;
  setShowTapNumber: (value: boolean) => void;
  hideBeverageSourceName: boolean;
  setHideBeverageSourceName: (value: boolean) => void;
  reverseBreweryAndName: boolean;
  showServings: boolean;
  setShowServings: (value: boolean) => void;
  showServingSize: boolean;
  setShowServingSize: (value: boolean) => void;
  showServingCurrency: boolean;
  setShowServingCurrency: (value: boolean) => void;
  showStripes: boolean;
  setShowStripes: (value: boolean) => void;
  showLocation: boolean;
  setShowLocation: (value: boolean) => void;
  showTapsDescription: boolean;
  setShowTapsDescription: (value: boolean) => void;
  premium: boolean;
  showLogo: boolean;
  setShowLogo: (value: boolean) => void;
  beverageImageSize: string;
  setBeverageImageSize: (value: string) => void;
  setShowBeverageImage: (value: boolean) => void;
  backgroundImageUrl: string | null;
  backgroundImagePreviewUrl: string | null;
  showBackgroundImageHelper: boolean;
  setShowBackgroundImageHelper: (value: boolean) => void;
  handleBackgroundImageSelection: (file: File) => void;
  removeBackgroundImage: () => void;
  showFooterText: boolean;
  setShowFooterText: (value: boolean) => void;
  footerText: string;
  setFooterText: (value: string) => void;
}
