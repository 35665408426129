import IconPremium from '../../images/icon-premium.svg';
export const AddCard = ({
  title,
  nonPremiumMessage,
  onClick,
  nonPremium
}: {
  title: string;
  nonPremiumMessage: string;
  onClick: () => void;
  nonPremium: boolean;
}) => (
  <div
    onClick={onClick}
    className="w-[296px] cursor-pointer relative h-auto min-h-[260px] border-accent-grey rounded-md border-2 border-dashed flex flex-col justify-center items-center gap-1">
    {nonPremium && (
      <img alt="Icon Premium Feature" src={IconPremium} width={32} height={32} className="absolute top-2 right-2 rotate-[30deg]" />
    )}
    <span className="text-[48px] leading-[26px] text-light-grey mb-3">+</span>
    <span className="text-light-grey text-sm font-bold uppercase">{title}</span>
    {nonPremium && <span className="text-golden text-sm font-bold uppercase">{nonPremiumMessage}</span>}
  </div>
);
