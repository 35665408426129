import React from 'react';
import { useTranslation } from 'react-i18next';
import { getVolumeUnitsForLanguage } from '../util/volumeUnits';

import { Select } from './shared/inputs';

interface VolumeUnitDropdownProps {
  volumeUnit: string;
  setVolumeUnit: (volumeUnit: string) => void;
  label?: string;
}

export function VolumeUnitDropdown({ volumeUnit, setVolumeUnit, label = 'Volume Unit' }: VolumeUnitDropdownProps) {
  const { i18n } = useTranslation();
  const volumeUnits = getVolumeUnitsForLanguage(i18n.language);

  return (
    <Select
      value={volumeUnit}
      options={volumeUnits.map((value) => ({ value: value.value, label: `${value.name} (${value.value})` }))}
      onChange={(value) => setVolumeUnit(value as string)}
      label={label}
    />
  );
}
