import { CircularProgress } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';
import { SessionContext } from '../../contexts/session';
import { getMessageFromError, spreadMutations } from '../../util/graphql';

import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import { DeleteDialog } from '../../components/DeleteDialog';
import { FeedbackBar } from '../../components/FeedbackBar';
import { LanguageDropdown } from '../../components/LanguageDropdown';
import { EditLocationDialog } from '../../components/LocationDialog';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';
import { Button } from '../../components/shared/Button';
import { GET_SUBSCRIPTION_DETAILS } from '../../contexts/queries';
import { AddCard } from './AddCard';
import AddMemberDialog from './AddMemberDialog';
import { LocationCard } from './LocationCard';
import { ManageLocationUsers } from './ManageLocationUsers';
import { MemberCard } from './MemberCard';
import { CHANGE_PASSWORD, DELETE_ACCOUNT, DELETE_LOCATION_MEMBER, UPDATE_ACCOUNT, UPDATE_LANGUAGE } from './mutations';
import { PlanCard, useAccountPlan } from './PlanCard';
import { GET_USER } from './queries';
import { Input } from '../../components/shared/inputs';

export const AccountSettings = () => {
  const { t, i18n } = useTranslation();
  const { user, account, locations, locLoading, locRefetch, accountRefetch, logout, premium, isOwner } = useContext(SessionContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<any>(undefined);
  const [validating, setValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [editLocation, setEditLocation] = useState<any>(null);
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const {
    loading: getUserLoading,
    error,
    data
  } = useQuery(GET_USER, {
    variables: { id: user?._id },
    fetchPolicy: 'cache-and-network'
  });

  const { data: subsDetailData } = useQuery(GET_SUBSCRIPTION_DETAILS, {
    variables: { id: account?._id },
    fetchPolicy: 'cache-and-network',
    skip: !account?._id
  });

  const [deleteLocationMember, { loading: deleteMemberLoading }] = useMutation(DELETE_LOCATION_MEMBER);

  const [mutationResults, status] = spreadMutations([
    useMutation(UPDATE_ACCOUNT),
    useMutation(CHANGE_PASSWORD),
    useMutation(DELETE_ACCOUNT),
    useMutation(UPDATE_LANGUAGE)
  ]);

  const [updateAccount, changePassword, deleteAccount, updateLanguage] = mutationResults as any[];
  const { loading } = status as any;

  const planData = useAccountPlan(account, subsDetailData);

  const handleSave = async () => {
    setValidating(true);
    if (firstName && lastName && email) {
      try {
        await updateAccount({
          variables: {
            firstName,
            lastName,
            email
          }
        });
        if (password && confirmPassword && password === confirmPassword) {
          await changePassword({ variables: { password } });
        }
        await updateLanguage({ variables: { language } });
      } catch (e) {
        setErrorMessage(getMessageFromError(e));
        console.log(e, getMessageFromError(e));
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data && data.user) {
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.local.email);
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  const handleDeleteAccount = async () => {
    const {
      data: {
        deleteAccount: { success }
      }
    } = await deleteAccount();
    if (success) {
      logout();
      window.location.pathname = '/login';
    }
  };

  const handleLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
    moment.locale(lang);
    setLanguage(lang);
  };

  const addLocation = () => {
    if (planData.isPremium) {
      let location = {
        _id: null,
        name: ''
      };

      setEditLocation(location);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const addMember = () => {
    if (planData.isPremium) {
      setShowAddMemberDialog(true);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const [managedUserEmail, setManagedUserEmail] = useState<string | null>(null);

  const manageLocations = (email: string) => {
    if (planData.isPremium) {
      setManagedUserEmail(email);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const onRemoveMember = (email: string) => {
    setMemberToDelete(email);
  };

  const handleDeleteMember = async (email: string) => {
    const result = await deleteLocationMember({
      variables: {
        locationIds: account.users.find((u: any) => u.user.local.email === email).locations,
        email: email
      }
    });
    if (result.data.deleteMemberUser.success) {
      setMemberToDelete(undefined);
      await accountRefetch();
    }
  };

  const myUserLocationsIds = account?.users.find((u: any) => u.user.local.email === user.local.email)?.locations;
  const locationsImMemberOf = isOwner ? locations : locations?.filter((l: any) => myUserLocationsIds.includes(l._id));

  const handleLogOut = async () => {
    logout();
    window.location.pathname = '/login';
  };

  const onSuccess = async () => {
    await locRefetch();

    setEditLocation(null);
  };

  return (
    <Layout title={t('Account.Settings')} className="md:mb-24 font-public-sans flex flex-col gap-10 !p-0  min-w-[290px]">
      <div className="flex flex-col gap-10 p-8 pt-16">
        <FeedbackBar message={errorMessage} />
        {planData?.isPendingPayment && (
          <div className="border border-orange rounded-xl p-2 flex flex-col items-center w-full">
            <h1 className="text-[1.5rem] font-bold p-2 bg-red border border-orange text-white rounded-xl">
              {t('Plans.BasicChargeFailed')}
            </h1>
            <h2 className="text-sm font-bold text-dark-grey my-[10px]">{t('Plans.PaymentFailedWarning')}</h2>
          </div>
        )}
        <div className="flex gap-6 flex-col">
          <h2 className="text-sm font-bold text-dark-grey uppercase">{t('Account.Basics')}</h2>
          <div className="flex flex-wrap gap-4 gap-y-6">
            <Input
              containerClassName="max-w-[460px] flex-grow w-full"
              label={t('Account.FirstName')}
              value={firstName}
              error={validating && !firstName}
              onChange={(e) => setFirstName(e as string)}
            />
            <Input
              containerClassName="max-w-[460px] flex-grow w-full"
              label={t('Account.LastName')}
              value={lastName}
              error={validating && !lastName}
              onChange={(e) => setLastName(e as string)}
            />
            <Input
              containerClassName="max-w-[460px] flex-grow w-full"
              label={t('Account.Email')}
              value={email}
              error={validating && !email}
              onChange={(e) => setEmail(e as string)}
            />
          </div>
        </div>
        <div className="flex gap-6 flex-col">
          <h2 className="text-sm font-bold text-dark-grey uppercase">{t('Account.AppLanguage')}</h2>
          <LanguageDropdown onChange={handleLanguage} />
        </div>
        <div className="flex gap-6 flex-col">
          <h2 className="text-sm font-bold text-dark-grey uppercase -mb-5">{t('Account.Security')}</h2>
          <p className="text-dark-grey">{t('Account.ChangePassword')}</p>
          <div className="flex flex-wrap gap-4 gap-y-6">
            <Input
              containerClassName="max-w-[460px] flex-grow w-full"
              label={t('Account.NewPassword')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e as string)}
              autoComplete="new-password"
            />
            <Input
              containerClassName="max-w-[460px] flex-grow w-full"
              type="password"
              label={t('Account.ConfirmNewPassword')}
              value={confirmPassword}
              error={!!password && !!confirmPassword && (password !== confirmPassword ? t('Account.PasswordsDontMatch') : undefined)}
              onChange={(e) => setConfirmPassword(e as string)}
              autoComplete="new-password"
            />
          </div>
        </div>

        <div className="flex gap-6 flex-col">
          <h2 className="text-sm font-bold text-dark-grey uppercase">{t('Account.LocationsTitle')}</h2>
          <div className="flex flex-wrap gap-6">
            {locLoading ? (
              <CircularProgress className="mx-auto" />
            ) : (
              <>
                {sortBy(locationsImMemberOf, (location) => location.name).map((location, i) => (
                  <LocationCard key={i} location={location} onEdit={() => setEditLocation(location)} />
                ))}
                <AddCard
                  title={t('Account.NewLocationCardTitle')}
                  nonPremiumMessage={t('Account.NewLocationCardPremium')}
                  onClick={addLocation}
                  nonPremium={!planData.isPremium}
                />
              </>
            )}
          </div>
        </div>
        {isOwner && (
          <>
            <div className="flex gap-6 flex-col">
              <h2 className="text-sm font-bold text-dark-grey uppercase">{t('Account.Members')}</h2>
              <div className="flex flex-wrap gap-6">
                {!premium
                  ? null
                  : account?.users
                      .filter((member: any) => !!member.user && member.user.local.email !== user.local.email)
                      .map((u: any, i: number) => (
                        <MemberCard
                          key={i}
                          member={u}
                          deleteMemberLoading={deleteMemberLoading}
                          onManageLocations={() => manageLocations(u.user.local.email)}
                          onRemove={() => onRemoveMember(u.user.local.email)}
                        />
                      ))}
                <AddCard
                  title={t('Account.AddMember')}
                  nonPremiumMessage={t('Account.NewLocationCardPremium')}
                  onClick={addMember}
                  nonPremium={!planData.isPremium}
                />
              </div>
            </div>
            <div className="flex gap-6 flex-col">
              <h2 className="text-sm font-bold text-dark-grey uppercase">{t('Account.CurrentPlan')}</h2>
              <PlanCard {...planData} />
            </div>
            <div className="flex gap-4 flex-col">
              <h2 className="text-sm font-bold text-dark-orange uppercase">{t('Account.DangerZone')}</h2>
              <p className="text-dark-orange text-base">{t('Account.DeleteAccount')}</p>

              <Button variant="destructive" className="w-fit" onClick={() => setShowDeleteAccountConfirmation(true)}>
                {t('Account.DeleteMyAccount')}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="border-t p-4 flex justify-end items-center sticky bottom-0 left-0 w-full bg-white gap-x-2 z-10">
        <Button variant="secondary" onClick={handleLogOut} disabled={loading || getUserLoading}>
          {t('Account.LogOut')}
        </Button>
        <Button variant="primary" disabled={loading || getUserLoading} onClick={handleSave}>
          {t('Account.Save')}
        </Button>
      </div>
      <DeleteDialog
        show={showDeleteAccountConfirmation}
        onCancel={() => setShowDeleteAccountConfirmation(false)}
        onDelete={handleDeleteAccount}
        title={t('Account.PermanentlyDeleteAccountTitle')}
        description={t('Account.PermanentlyDeleteAccount')}
      />
      <DeleteDialog
        show={!!memberToDelete}
        onCancel={() => setMemberToDelete(false)}
        onDelete={() => handleDeleteMember(memberToDelete)}
        title={t('Account.PermanentlyDeleteAccountTitle')}
        description={t('Account.PermanentlyDeleteMemberAccount', { memberEmail: memberToDelete })}
      />

      <FeedbackBar message={error} />
      {managedUserEmail && <ManageLocationUsers userEmail={managedUserEmail} onClose={() => setManagedUserEmail(null)} />}
      <AddMemberDialog
        open={showAddMemberDialog}
        onClose={() => setShowAddMemberDialog(false)}
        onAdd={() => setShowAddMemberDialog(false)}
      />
      {editLocation && (
        <EditLocationDialog
          location={editLocation}
          account={account}
          subDetail={subsDetailData?.subscription}
          onCancel={() => setEditLocation(null)}
          onSuccess={onSuccess}
          canDelete={locations.length > 1}
        />
      )}
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureDescription={t('PremiumFeatureDialog.Features.MultiLocation')}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </Layout>
  );
};
