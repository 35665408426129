interface PageDensityHighProps {
  className?: string;
}

const PageDensityHigh = ({ className }: PageDensityHighProps) => (
  <svg width="128" height="126" viewBox="0 0 128 126" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="37.3333" height="14.3333" fill="currentColor" />
    <rect y="22.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect y="44.6666" width="37.3333" height="14.3333" fill="currentColor" />
    <rect y="67" width="37.3333" height="14.3333" fill="currentColor" />
    <rect y="89.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect y="111.667" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" y="22.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" y="44.6666" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" y="67" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" y="89.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="45.333" y="111.667" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" y="22.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" y="44.6666" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" y="67" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" y="89.3334" width="37.3333" height="14.3333" fill="currentColor" />
    <rect x="90.667" y="111.667" width="37.3333" height="14.3333" fill="currentColor" />
  </svg>
);

export default PageDensityHigh;
