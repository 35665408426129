import React, { useMemo } from 'react';

interface TextAreaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | boolean;
  value?: string;
  onChange?: (value: string) => void;
  containerClassName?: string;
  rows?: number;
}

const getTextAreaRows = (value: string, minRows: number = 2): number => {
  if (!value) return minRows;
  const lines = value.split('\n').length;
  return Math.max(minRows, lines);
};

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  value = '',
  onChange,
  className = '',
  containerClassName = '',
  rows,
  ...props
}) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const hasValue = value !== undefined && value !== '';
  const showLabel = isFocused || hasValue;

  const calculatedRows = useMemo(() => {
    if (rows !== undefined) return rows;
    return getTextAreaRows(value ?? '', 2);
  }, [rows, value]);

  const inputClassName = `
    peer w-full px-4 py-3
    border rounded-[4px] 
    outline-none 
    focus:ring-0
    text-base 
    leading-6
    placeholder-transparent 
    resize-none
    ${
      error ? 'text-red' : !props.disabled ? 'text-blackish border-light-grey hover:border-dark-grey' : 'text-light-grey border-accent-grey'
    }
    ${error ? 'border-red focus:border-red' : !!isFocused && 'border-hover-green focus:border-hover-green'}
    transition-all duration-200 ease-in-out
    ${className}
  `;

  const labelClassName = `
    absolute left-3 px-1
    pointer-events-none
    text-light-blackish
    whitespace-nowrap
    bg-white
    transform
    -translate-y-1/2
    transition-all duration-200 ease-in-out
    peer-placeholder-shown:text-base
    peer-placeholder-shown:top-6
    peer-placeholder-shown:px-0
    peer-placeholder-shown:left-4
    [div:focus-within>&]:left-3
    [div:focus-within>&]:px-1
    [div:focus-within>&]:text-sm
    ${hasValue ? 'top-0 text-sm' : 'peer-focus:left-3 peer-focus:px-1 peer-focus:top-0 peer-focus:text-sm text-sm'}
    ${error && 'text-red [div:focus-within>&]:text-red'}
  `;

  return (
    <div className={`relative w-full ${containerClassName}`}>
      <div className="relative">
        <textarea
          value={value ?? ''}
          onChange={(e) => onChange?.(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={!showLabel ? label : ''}
          rows={calculatedRows}
          className={inputClassName}
          {...props}
        />

        {label && <label className={labelClassName}>{label}</label>}
        {typeof error === 'string' && <p className="mt-1 text-xs text-red-500">{error}</p>}
      </div>
    </div>
  );
};
