import React from 'react';
import { CURRENCIES_ARRAY } from '../util/currencies';
import { Select } from './shared/inputs';

interface CurrencyDropdownProps {
  currency: string;
  setCurrency: (currency: string) => void;
  label?: string;
}

export function CurrencyDropdown({ currency, setCurrency, label = 'Currency' }: CurrencyDropdownProps) {
  return (
    <Select
      value={currency}
      options={CURRENCIES_ARRAY.map((value) => ({ value: value.id, label: `${value.name} (${value.symbol})` }))}
      onChange={(value) => setCurrency(value as string)}
      label={label}
    />
  );
}
