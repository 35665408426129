import React from 'react';

import { useTranslation } from 'react-i18next';
import { Select } from './shared/inputs';

const options = [
  { value: 'en-US', label: '\uD83C\uDDFA\uD83C\uDDF8 English' },
  { value: 'es', label: '\ud83c\uddea\ud83c\uddf8 Español' }
];

export const LanguageDropdown = ({ onChange }) => {
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    i18n.changeLanguage(e);
    onChange?.(e);
  };

  return (
    <div className="w-[220px]">
      <Select label="Language" value={i18n.language || 'en-US'} onChange={handleChange} options={options} />
    </div>
  );
};
