interface PageDensityLowProps {
  className?: string;
}

const PageDensityLow = ({ className }: PageDensityLowProps) => (
  <svg width="128" height="126" viewBox="0 0 128 126" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="26.6667" height="13.5" fill="currentColor" />
    <rect y="37.5" width="26.6667" height="13.5" fill="currentColor" />
    <rect y="75" width="26.6667" height="13.5" fill="currentColor" />
    <rect y="112.5" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="50.667" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="50.667" y="37.5" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="50.667" y="75" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="50.667" y="112.5" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="101.333" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="101.333" y="37.5" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="101.333" y="75" width="26.6667" height="13.5" fill="currentColor" />
    <rect x="101.333" y="112.5" width="26.6667" height="13.5" fill="currentColor" />
  </svg>
);

export default PageDensityLow;
