interface PageColumnStyle3Props {
  className?: string;
}

const PageColumnStyle3 = ({ className }: PageColumnStyle3Props) => (
  <svg width="145" height="126" viewBox="0 0 145 126" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="43" height="14.3333" fill="currentColor" />
    <rect y="22.3334" width="43" height="14.3333" fill="currentColor" />
    <rect y="44.6666" width="43" height="14.3333" fill="currentColor" />
    <rect y="67" width="43" height="14.3333" fill="currentColor" />
    <rect y="89.3334" width="43" height="14.3333" fill="currentColor" />
    <rect y="111.667" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" y="22.3334" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" y="44.6666" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" y="67" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" y="89.3334" width="43" height="14.3333" fill="currentColor" />
    <rect x="51" y="111.667" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" y="22.3334" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" y="44.6666" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" y="67" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" y="89.3334" width="43" height="14.3333" fill="currentColor" />
    <rect x="102" y="111.667" width="43" height="14.3333" fill="currentColor" />
  </svg>
);

export default PageColumnStyle3;
