import React from 'react';

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | boolean;
  value?: string | number | null;
  onChange?: (value: string | number | null) => void;
  containerClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  numberMode?: 'float' | 'integer';
  precision?: number;
  cantBeEmpty?: boolean;
}

export const Input: React.FC<InputProps> = ({
  label,
  error,
  value = '',
  onChange,
  type = 'text',
  className = '',
  containerClassName = '',
  startIcon,
  endIcon,
  numberMode = 'integer',
  precision = 2,
  cantBeEmpty = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [rawInput, setRawInput] = React.useState<string>('');

  const hasValue = value !== undefined && value !== '';
  const showLabel = isFocused || hasValue;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (type === 'number') {
      if (inputValue === '') {
        setRawInput('');
        onChange?.(cantBeEmpty ? 0 : null);
        return;
      }

      if (numberMode === 'float' && !isNaN(parseFloat(inputValue))) {
        if (!isNaN(Number(inputValue))) {
          setRawInput(inputValue);
          onChange?.(Number(inputValue));
        }
      } else if (numberMode === 'integer' && !isNaN(parseInt(inputValue))) {
        onChange?.(Math.round(Number(inputValue)));
      }
    } else {
      onChange?.(inputValue);
    }
  };

  const displayValue = React.useMemo(() => {
    if (value === null || value === undefined) return '';

    if (type === 'number' && numberMode === 'float') {
      return rawInput || (typeof value === 'number' ? value.toFixed(precision) : value.toString());
    }

    return value.toString();
  }, [value, type, numberMode, precision, rawInput]);

  const inputClassName = `
    peer w-full px-4 py-3
    border rounded-[4px] 
    outline-none 
    focus:ring-0
    text-base 
    leading-6
    placeholder-transparent 
    resize-none
    ${
      error ? 'text-red' : !props.disabled ? 'text-blackish border-light-grey hover:border-dark-grey' : 'text-light-grey border-accent-grey'
    }
    ${error ? 'border-red focus:border-red' : !!isFocused && 'border-hover-green focus:border-hover-green'}
    transition-all duration-200 ease-in-out
    ${className}
  `;

  const labelClassName = `
    absolute left-3 px-1
    pointer-events-none
    text-light-blackish
    whitespace-nowrap
    bg-white
    transform
    -translate-y-1/2
    transition-all duration-200 ease-in-out
    peer-placeholder-shown:text-base
    peer-placeholder-shown:top-1/2
    peer-placeholder-shown:translate-y-1/2
    peer-placeholder-shown:px-0
    peer-placeholder-shown:left-4
    [div:focus-within>&]:top-0
    [div:focus-within>&]:left-3
    [div:focus-within>&]:px-1
    [div:focus-within>&]:text-sm
    ${hasValue ? 'top-0 text-sm' : 'top-1/2 text-base'}
    ${error && 'text-red [div:focus-within>&]:text-red'}
  `;

  return (
    <div className={`relative w-full ${containerClassName}`}>
      <div className="relative">
        <div className="relative flex items-center">
          {startIcon && <div className="absolute left-4">{startIcon}</div>}
          <input
            type={type}
            value={displayValue}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={!showLabel ? label : ''}
            className={`
              ${inputClassName}
              h-[56px]
              ${startIcon ? 'pl-12' : ''}
              ${endIcon ? 'pr-12' : ''}
            `}
            {...props}
          />
          {endIcon && <div className="absolute right-4 flex items-center">{endIcon}</div>}
        </div>

        {label && <label className={labelClassName}>{label}</label>}
        {typeof error === 'string' && <p className="mt-1 text-xs text-red-500">{error}</p>}
      </div>
    </div>
  );
};
