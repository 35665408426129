import React, { useState, useMemo, useEffect } from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import { RadioGroup } from './shared/RadioGroup';

import { Tap } from '../types';
import { useTranslation } from 'react-i18next';

interface ServingState {
  [key: string]: {
    include: boolean;
    copyServings: boolean;
  };
}

interface TapListProps {
  taps: Tap[];
  setSelectedTaps: (taps: TapWithSettings[]) => void;
}

export interface TapWithSettings {
  _id: string;
  copyServings: boolean;
}

const CopyTapList: React.FC<TapListProps> = ({ taps, setSelectedTaps }) => {
  const { t } = useTranslation();

  const initialState = useMemo(
    () =>
      taps.reduce((acc: ServingState, tap) => {
        acc[tap._id] = { include: true, copyServings: true };
        return acc;
      }, {}),
    [taps]
  );

  const [selectedServings, setSelectedServings] = useState<ServingState>(initialState);

  useEffect(() => {
    if (taps.length > 0) {
      const initialSelectedTaps = taps.map((tap) => ({
        _id: tap._id,
        copyServings: true
      }));
      setSelectedTaps(initialSelectedTaps);
    }
  }, [taps, setSelectedTaps]);

  const updateSelectedTaps = (newServings: ServingState) => {
    const selectedTapSettings = Object.entries(newServings)
      .filter(([_, settings]) => settings.include)
      .map(([tapId, settings]) => ({
        _id: tapId,
        copyServings: settings.copyServings
      }));

    setSelectedTaps(selectedTapSettings);
  };

  const handleCheckboxChange = (tapId: string) => {
    const newServings = {
      ...selectedServings,
      [tapId]: {
        ...selectedServings[tapId],
        include: !selectedServings[tapId]?.include
      }
    };
    setSelectedServings(newServings);
    updateSelectedTaps(newServings);
  };

  const handleRadioChange = (tapId: string, value: string) => {
    const newServings = {
      ...selectedServings,
      [tapId]: {
        ...selectedServings[tapId],
        copyServings: value === 'yes'
      }
    };
    setSelectedServings(newServings);
    updateSelectedTaps(newServings);
  };

  return (
    <Grid container direction="column" className="font-public-sans">
      <Grid container alignItems="center" className="border-b border-gray-300 font-semibold uppercase text-dark-orange bg-light-orange p-5">
        <Grid item xs={2} sm={1} className="font-bold">
          {t('Taps.Include')}
        </Grid>
        <Grid item xs={6} sm={9} className="px-4 text-left font-bold">
          {t('Taps.Tap')}
        </Grid>
        <Grid item xs={4} sm={2} className="font-bold">
          {t('CopyServingsDialog.CopyServings')}
        </Grid>
      </Grid>

      {taps.map((tap) => (
        <Grid container alignItems="center" key={tap.name} className="py-2 border-b border-gray-200 p-5">
          <Grid item xs={2} sm={1}>
            <div className="ml-2.5">
              <Checkbox checked={!!selectedServings[tap._id]?.include} onChange={() => handleCheckboxChange(tap._id)} color="primary" />
            </div>
          </Grid>

          <Grid item xs={6} sm={8} md={9} className="px-4 text-left text-md">
            {tap.beverage?.name}
          </Grid>

          <Grid item xs={4} sm={3} md={2} className="w-full">
            {selectedServings[tap._id]?.include && (
              <RadioGroup
                name={`${tap.name}-copyServings`}
                color="green"
                value={selectedServings[tap._id]?.copyServings ? 'yes' : 'no'}
                onChange={(value) => handleRadioChange(tap._id, String(value))}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ]}
                className="w-full col-span-2"
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CopyTapList;
