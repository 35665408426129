import React from 'react';

interface RadioOption {
  label: React.ReactNode;
  value: string | number | boolean;
  disabled?: boolean;
}

interface RadioGroupProps {
  name?: string;
  value?: string | number | boolean;
  color?: 'orange' | 'green';
  onChange?: (value: string | number | boolean) => void;
  options: RadioOption[];
  label?: string;
  error?: string;
  column?: boolean;
  className?: string;
  disabled?: boolean;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  value,
  onChange,
  color = 'orange',
  options,
  label,
  error,
  column = false,
  className = '',
  disabled = false
}) => {
  const stringValue = String(value);

  const handleChange = (optionValue: string | number | boolean) => {
    if (disabled) return;

    const newValue = optionValue === 'true' ? true : optionValue === 'false' ? false : optionValue;

    onChange?.(newValue);
  };

  return (
    <div className={className}>
      {label && <label className="block text-sm font-medium text-light-blackish mb-4">{label}</label>}

      <div className={`flex ${column ? 'flex-col gap-3' : 'flex-row gap-6'}`}>
        {options.map((option, index) => (
          <RadioGroupOptions
            color={color}
            key={index}
            name={name}
            option={option}
            index={index}
            stringValue={stringValue}
            error={error}
            disabled={disabled}
            handleChange={handleChange}
          />
        ))}
      </div>

      {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
    </div>
  );
};

interface RadioGroupOptionsProps {
  color?: 'orange' | 'green';
  name?: string;
  option: RadioOption;
  index: number;
  stringValue: string;
  error?: string;
  disabled: boolean;
  handleChange: (value: string | number | boolean) => void;
}

const RadioGroupOptions = ({ index, option, stringValue, error, disabled, handleChange, name, color }: RadioGroupOptionsProps) => {
  const stringOptionValue = String(option.value);
  const isChecked = stringValue === stringOptionValue;
  const isDisabled = disabled || option.disabled;

  return (
    <label
      key={index}
      className={`
        relative flex items-center
        text-light-blackish
        ${isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
      `}>
      <input
        type="radio"
        name={name}
        className="sr-only"
        checked={isChecked}
        onChange={() => !isDisabled && handleChange(option.value)}
        disabled={isDisabled}
      />
      <span
        className={`
          w-5 h-5 rounded-full border-1 
          flex items-center justify-center
          ${isChecked ? (color === 'green' ? 'border-green' : 'border-orange') : error ? 'border-red' : 'border-light-blackish'}
          ${!isDisabled && color === 'green' ? 'hover:border-green' : 'hover:border-orange'}
        `}>
        {isChecked && (
          <span
            className={`
            w-2.5 h-2.5 rounded-full
            ${error ? 'bg-red' : color === 'green' ? 'bg-green' : 'bg-orange'}
          `}
          />
        )}
      </span>
      <span className="ml-2 text-sm text-blackish">{option.label}</span>
    </label>
  );
};
