interface PageColumnStyle1Props {
  className?: string;
}

const PageColumnStyle1 = ({ className }: PageColumnStyle1Props) => (
  <svg className={className} width="44" height="126" viewBox="0 0 44 126" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="43" height="14.3333" fill="currentColor" />
    <rect x="0.5" y="22.3333" width="43" height="14.3333" fill="currentColor" />
    <rect x="0.5" y="44.6667" width="43" height="14.3333" fill="currentColor" />
    <rect x="0.5" y="67" width="43" height="14.3333" fill="currentColor" />
    <rect x="0.5" y="89.3333" width="43" height="14.3333" fill="currentColor" />
    <rect x="0.5" y="111.667" width="43" height="14.3333" fill="currentColor" />
  </svg>
);

export default PageColumnStyle1;
