import React, { FC, Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/shared/Button';
import { EditServingDialog } from '../../taps/servings/EditServingDialog';
import { CopyServingsDialog } from '../../taps/servings/CopyServingsDialog';
import { resolveVesselName, getServingSizeLabel, getServingPriceLabel } from '../../../util/lang';
import BinIcon from '../../../images/icon-bin';
import Tooltip from '../../../components/Tooltip';
import { find, without } from 'lodash';
import { Serving } from '../../../types';
import PlusIcon from '../../../images/icon-plus';
import CopyIcon from '../../../images/icon-copy';

interface Props {
  servings: Serving[];
  setServings: (servings: Serving[]) => void;
  editServing: Serving | null;
  userVessels: any[];
  copySergingsDialogOpen: boolean;
  tap: any;
  onAddServing: () => void;
  onEditServing: (serving: Serving) => void;
  onCancelEdit: () => void;
  onDeleteServing: () => void;
  onSaveServing: (serving: Serving) => void;
  onCopyServings: (servings: Serving[]) => void;
  onToggleCopyDialog: () => void;
}

export const TapEditServings: FC<Props> = ({
  servings,
  setServings,
  editServing,
  userVessels,
  copySergingsDialogOpen,
  tap,
  onAddServing,
  onEditServing,
  onCancelEdit,
  onDeleteServing,
  onSaveServing,
  onCopyServings,
  onToggleCopyDialog
}) => {
  const { t } = useTranslation();

  const handleDeleteServing = (serving: Serving) => {
    if (serving && serving.key) {
      let allServings = [...servings];
      let matchedServing = find(allServings, (p) => p.key === serving.key);
      if (matchedServing) {
        allServings = without(allServings, matchedServing);
        setServings(allServings);
      }
    }
  };

  const servingsItemProps = {
    onEditServing,
    userVessels,
    handleDeleteServing
  };

  return (
    <>
      {servings.length > 0 && (
        <div className="absolute -top-12 right-0 flex items-center justify-end gap-4">
          <div className="mx-4 flex gap-2 whitespace-nowrap">
            <Button variant="primary" rounded color="primary" onClick={onAddServing}>
              <PlusIcon />
              <span className="hidden md:inline-block">{t('TapForm.AddServing')}</span>
            </Button>
            <Button variant="secondary" rounded color="blue" onClick={onToggleCopyDialog}>
              <CopyIcon />
              <span className="hidden md:inline-block">{t('TapForm.CopyFromExisting')}</span>
            </Button>
          </div>
        </div>
      )}
      {servings.length > 0 ? (
        <div className="grid grid-cols-[1fr_1fr_1fr_auto]">
          <ServingsHeader className="-mr-3">{String(t('TapForm.ServingType'))}</ServingsHeader>
          <ServingsHeader className="-mx-3">{String(t('TapForm.Size'))}</ServingsHeader>
          <ServingsHeader className="-mx-3">{String(t('TapForm.Price'))}</ServingsHeader>
          <ServingsHeader className="-ml-3" />

          {servings.map((serving: Serving, i) => (
            <Fragment key={i}>
              <ServingsItem {...servingsItemProps} serving={serving} />
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-6 p-16">
          <div className="text-dark-grey text-center text-md">{t('TapForm.NoServings')}</div>
          <div className="mx-6 flex gap-4 whitespace-nowrap">
            <Button variant="secondary" onClick={onToggleCopyDialog}>
              <CopyIcon />
              <span className="hidden md:inline-block">{t('TapForm.CopyFromExisting')}</span>
            </Button>
            <Button variant="primary" onClick={onAddServing}>
              <PlusIcon />
              <span className="hidden md:inline-block">{t('TapForm.AddServing')}</span>
            </Button>
          </div>
        </div>
      )}

      <EditServingDialog
        serving={editServing as Serving}
        onCancel={onCancelEdit}
        // @ts-ignore
        onDelete={onDeleteServing}
        onSave={onSaveServing}
      />

      <CopyServingsDialog currentTap={tap} open={copySergingsDialogOpen} onCopyServings={onCopyServings} onClose={onToggleCopyDialog} />
    </>
  );
};

interface ServingsHeaderProps {
  children?: ReactNode;
  className?: string;
}

const ServingsHeader = ({ children, className }: ServingsHeaderProps) => (
  <div className={`p-6 bg-light-orange uppercase text-dark-orange font-bold ${className}`}>{children}</div>
);

interface ServingsItemProps {
  serving: Serving;
  onEditServing: (serving: Serving) => void;
  userVessels: any[];
  handleDeleteServing: (serving: Serving) => void;
}

const ServingsItem = ({ serving, onEditServing, userVessels, handleDeleteServing }: ServingsItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className={`text-md hover:cursor-pointer p-6 -mr-3 border-t-accent-grey border-t-1 ${isHovered && 'text-orange'}`}
        onClick={() => onEditServing(serving)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {resolveVesselName(serving.vessel, userVessels)}
      </div>
      <div
        className={`text-md hover:cursor-pointer p-6 -mx-3 border-t-accent-grey border-t-1 ${isHovered && 'text-orange'}`}
        onClick={() => onEditServing(serving)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {getServingSizeLabel(serving)}
      </div>
      <div
        className={`text-md hover:cursor-pointer p-6 -mx-3 border-t-accent-grey border-t-1 ${isHovered && 'text-orange'}`}
        onClick={() => onEditServing(serving)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {getServingPriceLabel(serving, true)}
      </div>
      <Tooltip text="Delete Serving">
        <div
          className="relative hover:cursor-pointer p-6 -ml-3 border-t-accent-grey border-t-1 text-accent-grey hover:text-red"
          onClick={() => handleDeleteServing(serving)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <BinIcon />
        </div>
      </Tooltip>
    </>
  );
};
