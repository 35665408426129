import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../components/Layout';
import { SessionContext } from '../../contexts/session';
import { FeedbackBar } from '../../components/FeedbackBar';
import { COPY_TAPS, GET_TAPS } from './queries';
import { UserVesselsProvider } from '../../contexts/userVessels';
import CopyTapList, { TapWithSettings } from '../../components/CopyTapList';
import { Button } from '../../components/shared/Button';
import ArrowIcon from '../../images/icon-arrow';
import { Select } from '../../components/shared/inputs';

interface LocationOption {
  value: string;
  label: string;
}

export const CopyTaps = () => {
  const history = useHistory();
  const { currentLocation, locations } = useContext(SessionContext);
  const { t } = useTranslation();

  const [selectedTaps, setSelectedTaps] = useState<TapWithSettings[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationOption>({
    value: 'select',
    label: t('Taps.SelectLocation')
  });

  const [copyTaps, { loading: copyTapsLoading, error: copyTapsError }] = useMutation(COPY_TAPS, {
    onCompleted: () => {
      history.push('/taps');
    }
  });

  const { loading, error, data, refetch } = useQuery(GET_TAPS, {
    variables: { location: selectedLocation?.value },
    fetchPolicy: 'cache-and-network',
    skip: selectedLocation.value === 'select'
  });

  const handleCopyTaps = useCallback(async () => {
    try {
      const tapsToCopy = selectedTaps.map((tap) => ({
        tapId: tap._id,
        copyServings: tap.copyServings
      }));

      await copyTaps({
        variables: {
          tapsToCopy,
          targetLocationId: currentLocation._id
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [selectedTaps, currentLocation, copyTaps]);

  const handleNotHavingMultipleLocations = useCallback(() => {
    if (locations?.length === 1) {
      history.goBack();
    }
  }, [locations, history]);

  useEffect(() => {
    if (selectedLocation.value !== 'select') {
      refetch?.({ location: selectedLocation.value });
    }
  }, [selectedLocation, refetch]);

  useEffect(() => {
    handleNotHavingMultipleLocations();
  }, [handleNotHavingMultipleLocations]);

  return (
    <UserVesselsProvider>
      <Layout title={t('Taps.CopyTaps')}>
        <FeedbackBar message={copyTapsError || error} />

        <div className="flex flex-col space-y-6">
          <div className="px-4 pt-1.5 pb-3 border-b border-accent-grey">
            <button onClick={() => history.goBack()} className="p-2 hover:bg-light-grey rounded-full">
              <ArrowIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="px-10">
            <div className="w-full md:w-1/2 min-h-[80px]">
              <Select
                label={t('Taps.SelectLocation')}
                value={selectedLocation.value}
                options={[
                  {
                    value: 'select',
                    label: t('Taps.SelectLocation')
                  },
                  ...(locations || [])
                    .filter((l) => l._id !== currentLocation._id)
                    .map((l) => ({
                      value: l._id,
                      label: l.name
                    }))
                ]}
                onChange={(value) => {
                  const location = locations?.find((l) => l._id === value);
                  if (location) {
                    setSelectedLocation({
                      value: location._id,
                      label: location.name
                    });
                  }
                }}
                searchable
              />
            </div>
          </div>

          <div className="flex-1 min-h-[400px]">
            {loading && (
              <div className="flex flex-col items-center justify-center min-h-[400px]">
                <p>{t('Taps.LoadingMessage', { location: currentLocation.name })}</p>
                <div className="mt-10 w-8 h-8 border-4 border-orange border-t-transparent rounded-full animate-spin" />
              </div>
            )}

            {!loading && selectedLocation.value === 'select' && <div className="min-h-[400px]" />}

            {!loading && selectedLocation.value !== 'select' && <CopyTapList taps={data?.taps || []} setSelectedTaps={setSelectedTaps} />}
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-accent-grey p-4 md:relative md:border-0">
            <div className="flex justify-end space-x-2 max-w-[1000px] mx-auto">
              <Button variant="secondary" onClick={() => history.goBack()}>
                {t('Common.Cancel')}
              </Button>
              <Button
                variant="primary"
                disabled={selectedLocation.value === 'select' || copyTapsLoading}
                onClick={handleCopyTaps}
                className="whitespace-nowrap">
                {selectedTaps.length === 1 ? t('Taps.CopyOne') : t('Taps.Copy', { count: selectedTaps.length })}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </UserVesselsProvider>
  );
};
