import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/shared/inputs';

interface PageInfoProps {
  name: string;
  setName: (value: string) => void;
  number: number;
  setNumber: (value: number) => void;
  rotationDuration: number;
  setRotationDuration: (value: number) => void;
  tapStart: number;
  setTapStart: (value: number) => void;
  tapEnd: number;
  setTapEnd: (value: number) => void;
  hideTapListInputs?: boolean;
}

export const PageInfo = ({
  name,
  setName,
  number,
  setNumber,
  rotationDuration,
  setRotationDuration,
  tapStart,
  setTapStart,
  tapEnd,
  setTapEnd,
  hideTapListInputs
}: PageInfoProps) => {
  const { t } = useTranslation();

  return (
    <div className="p-6">
      <div className="grid grid-cols-12 gap-4">
        <h2 className="col-span-12 text-dark-grey uppercase font-bold ">{t('PageEditForm.Basics')}</h2>
        <Input
          label={t('PageEditForm.PageTitle')}
          value={name}
          onChange={(value) => setName(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-6"
        />
        <Input
          label={t('PageEditForm.PageNumber')}
          value={number}
          type="number"
          onChange={(value) => setNumber(Number(value))}
          className="w-full"
          containerClassName="col-span-6 md:col-span-2"
        />
        <Input
          label={t('PageEditForm.DurationShown')}
          type="number"
          value={rotationDuration}
          onChange={(value) => setRotationDuration(parseInt(value as string))}
          endIcon={<span className="text-light-grey">seconds</span>}
          className="w-full"
          containerClassName="col-span-6 md:col-span-2"
        />

        {!hideTapListInputs && (
          <>
            <h2 className="col-span-12 text-dark-grey uppercase font-bold  mt-8">{t('PageEditForm.TapList')}</h2>
            <Input
              label={t('PageEditForm.TapNoStart')}
              type="number"
              value={tapStart}
              onChange={(value) => setTapStart(parseInt(value as string))}
              className="w-full"
              containerClassName="col-span-4 md:col-span-4"
            />
            <Input
              label={t('PageEditForm.TapNoEnd')}
              type="number"
              value={tapEnd}
              onChange={(value) => setTapEnd(parseInt(value as string))}
              className="w-full"
              containerClassName="col-span-4 md:col-span-4"
            />
            <div className="flex flex-col col-span-4 md:col-span-2 w-[100px] border-1 bg-light-green border-hover-green rounded-md justify-center items-center">
              <div className="text-xs text-dark-green">{t('PageEditForm.TapsShown')}</div>
              <div className="text-md font-semibold text-dark-green">{tapEnd - tapStart + 1 || 0}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
