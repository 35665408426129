import React, { useRef } from 'react';
import Tooltip from './Tooltip';
import { humanReadableSize } from '../util/files';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from './shared/Button';
import InfoIcon from '../images/icon-info';

interface FileUploadProps {
  onFileSelected: (file: File, previewUrl: string) => void;
  maxFileSize: number;
  id: string;
  infoText?: React.ReactNode | string;
  labelClassName?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelected, maxFileSize, id, infoText, labelClassName }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const _handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files?.[0];

    if (!file) return;

    reader.onloadend = () => {
      onFileSelected(file, reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        ref={inputRef}
        id={`raised-button-file-${id}`}
        type="file"
        onChange={_handleImageChange}
      />
      <label htmlFor={`raised-button-file-${id}`} className={labelClassName}>
        <Button
          variant="secondary"
          size="md"
          className="px-5 mr-3 whitespace-nowrap"
          onClick={() => inputRef.current?.click()}
          type="button">
          {t('FileUpload.SelectFile')}
        </Button>
        <div className="text-xs text-light-blackish whitespace-nowrap text-wrap flex flex-col">
          <div className="flex items-center">
            {t('FileUpload.MaxSize')} {humanReadableSize(maxFileSize)}
            <span id="compress-info" style={{ marginLeft: '5px', cursor: 'pointer' }}>
              <InfoIcon />
            </span>
          </div>
          <div className="whitespace-pre-wrap">{infoText}</div>
        </div>
      </label>

      <Tooltip
        anchorSelect="#compress-info"
        clickable
        content={
          <Trans
            i18nKey="FileUpload.CompressInfo"
            t={t}
            values={{
              imageSize: humanReadableSize(maxFileSize)
            }}
            components={{
              linkAnchor: (
                <a href="https://tinypng.com" target="_blank" rel="noopener noreferrer" className="underline">
                  <span className="sr-only">tinypng website link</span>
                </a>
              )
            }}
          />
        }
      />
    </>
  );
};

export default FileUpload;
