import React from 'react';
import { makeStyles } from '@material-ui/core';
import ImageIcon from '../../../images/icon-image';

const useStyles = makeStyles((theme) => ({
  imagePage: {
    flex: 1,
    overflow: 'hidden',
    '& img': {
      width: `100vw`,
      height: `100%`,
      objectFit: 'cover'
    }
  }
}));

export const ImagePage = ({ page }) => {
  const classes = useStyles();

  return (
    <div className={classes.imagePage}>
      {!page.imageUrl ? (
        <div className="flex bg-device-background justify-center items-center w-full h-full font-public-sans">
          <div className="flex flex-col items-center bg-white p-12 gap-2">
            <ImageIcon className="w-1/2 text-dark-grey" />
            <h1 className="text-dark-grey text-3xl font-semibold">No Uploaded Image</h1>
            <p className="text-dark-grey text-md">Please use the Pourwall website to upload an image to display here</p>
          </div>
        </div>
      ) : (
        <img alt="custom page" src={page.imageUrl} />
      )}
    </div>
  );
};
