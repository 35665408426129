import React from 'react';
import { useTranslation } from 'react-i18next';

import FileUpload from '../../components/FileUpload';

import { MAX_SCREEN_IMAGE_SIZE_BYTES } from '../../util/files';

import { useFileUpload } from '../../hooks/useFileUpload';

type RouteParams = ReturnType<typeof useFileUpload>;

export const UploadImage: React.FC<RouteParams> = ({ previewUrl: imagePreviewUrl, error: imageError, handleFileSelection }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col px-6 pb-5">
      <div className="mt-10 mb-3">
        <h2 className="font-bold text-dark-grey uppercase">{t('PageEditForm.Image')}</h2>
      </div>

      <div className="">
        <FileUpload
          id="image-upload"
          onFileSelected={handleFileSelection}
          maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES}
          labelClassName="flex flex-row items-start gap-[5px]"
          infoText={<p className="text-xs font-public-sans text-light-blackish mt-0">{t('PageEditForm.DimesionInfo')}</p>}
        />
      </div>

      <div className="mt-4">
        {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" className="max-w-full h-auto sm:h-[250px] mt-4" />}
      </div>

      {!!imageError && (
        <div className="mt-4">
          <p className="text-red">{imageError.message}</p>
        </div>
      )}
    </div>
  );
};
