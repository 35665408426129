import React, { useRef, useState, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ChevronDown from '../../../images/chevron-down';
import { Card } from '../Card';

interface InputOption {
  value: string | number;
  label: React.ReactNode;
  className?: string;
}

interface CardOption {
  value: string | number;
  label: string;
  children?: React.ReactNode;
}

interface SelectProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | boolean;
  options?: (InputOption | CardOption)[];
  variant?: 'default' | 'card';
  value?: string | number | null;
  onChange?: (value: string | number | null) => void;
  containerClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  searchable?: boolean;
  cardClassName?: string;
  cardContentClassName?: string;
  hideCardLabel?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  label,
  error,
  options = [],
  value = '',
  onChange,
  className = '',
  containerClassName = '',
  startIcon,
  endIcon,
  searchable = false,
  variant = 'default',
  cardClassName,
  cardContentClassName,
  hideCardLabel = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left?: number | 'auto'; right?: number | 'auto'; width: string }>(
    { top: 0, left: 0, right: 0, width: 'auto' }
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerRef.current &&
        dropdownRef.current &&
        !containerRef.current.contains(e.target as Node) &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        closeDropdown();
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  const updatePosition = () => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const viewportWidth = document.documentElement.clientWidth; // This excludes scrollbar
    const isPastMiddle = rect.left > viewportWidth / 2;

    setDropdownPosition({
      top: rect.bottom + 8,
      left: isPastMiddle ? 'auto' : rect.left,
      right: isPastMiddle ? viewportWidth - rect.right : 'auto',
      width: `${rect.width}px`
    });
  };

  useEffect(() => {
    if (showOptions) {
      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [showOptions]);

  const closeDropdown = () => {
    setShowOptions(false);
    setSearchText('');
  };

  const toggleDropdown = () => {
    if (!props.disabled) {
      setShowOptions((prev) => !prev);
    }
  };

  const handleOptionClick = (option: InputOption | CardOption) => {
    if (option && option.label !== 'No Options') {
      onChange?.(option.value);
      closeDropdown();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchable) {
      setSearchText(e.target.value);
      toggleDropdown();
    }
  };

  const handleBlur = (e: React.FocusEvent) => {
    requestAnimationFrame(() => {
      const activeElement = document.activeElement;
      const dropdownElement = dropdownRef.current;
      const inputElement = inputRef.current;

      if (dropdownElement && inputElement && !dropdownElement.contains(activeElement) && !inputElement.contains(activeElement)) {
        setShowOptions(false);
        setIsFocused(false);
        setSearchText('');
      }
    });
  };

  const hasValue = value !== undefined && value !== '';
  const showLabel = isFocused || hasValue;

  const filteredOptions = useMemo(() => {
    if (!searchable || !searchText) return options;

    return options.filter((opt) => {
      if ('label' in opt && opt.label) {
        return opt.label.toString().toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    });
  }, [options, searchable, searchText]);

  const displayValue = useMemo(() => {
    if (searchable && isFocused && showOptions) {
      return searchText;
    }

    const selectedOption = options.find((opt) => {
      const optVal = JSON.stringify(opt.value);
      const compareVal = JSON.stringify(value);
      return optVal === compareVal;
    });

    return selectedOption?.label?.toString() ?? '';
  }, [options, value, searchable, isFocused, showOptions, searchText]);

  const inputClassName = `
    peer w-full px-4 py-3
    border rounded-[4px] 
    outline-none 
    focus:ring-0
    text-base 
    leading-6
    placeholder-transparent 
    resize-none
    cursor-pointer
    bg-white
    ${
      error ? 'text-red' : !props.disabled ? 'text-blackish border-light-grey hover:border-dark-grey' : 'text-light-grey border-accent-grey'
    }
    ${error ? 'border-red focus:border-red' : !!isFocused && 'border-hover-green focus:border-hover-green'}
    transition-all duration-200 ease-in-out
    ${className}
  `;

  const labelClassName = `
    absolute left-3 px-1
    pointer-events-none
    text-light-blackish
    whitespace-nowrap
    bg-white
    transform
    -translate-y-1/2
    transition-all duration-200 ease-in-out
    peer-placeholder-shown:text-base
    peer-placeholder-shown:top-1/2
    peer-placeholder-shown:translate-y-1/2
    peer-placeholder-shown:px-0
    peer-placeholder-shown:left-4
    [div:focus-within>&]:top-0
    [div:focus-within>&]:left-3
    [div:focus-within>&]:px-1
    [div:focus-within>&]:text-sm
    ${hasValue ? 'top-0 text-sm' : 'top-1/2 text-base'}
    ${error && 'text-red [div:focus-within>&]:text-red'}
  `;

  return (
    <div ref={containerRef} className={`relative w-full ${containerClassName}`} onBlur={handleBlur}>
      {variant === 'card' ? (
        <div className="relative">
          <div
            className={`
              relative flex items-center justify-between w-full h-[56px] px-4
              border rounded-[4px] cursor-pointer bg-white
              ${error ? 'border-red' : !props.disabled ? 'border-light-grey hover:border-dark-grey' : 'border-accent-grey'}
              ${error ? 'text-red' : !props.disabled ? 'text-blackish' : 'text-light-grey'}
              ${showOptions && '!border-hover-green'}
            `}
            onClick={toggleDropdown}>
            {label && (
              <span
                className={`
                  absolute left-3 px-1 bg-white
                  transform -translate-y-1/2 pointer-events-none
                  ${hasValue || showOptions ? '-top-0.5 text-sm' : 'top-1/2 text-base'}
                  ${error ? 'text-red' : 'text-light-blackish'}
                  transition-all duration-200
                `}>
                {label}
              </span>
            )}
            <span
              className={`
              text-base
              ${variant !== 'card' && label && (hasValue || showOptions) && 'mt-3'}
            `}>
              {displayValue || 'Select an option'}
            </span>
            <ChevronDown
              className={`
              transition-transform w-4 h-4
              ${showOptions && 'rotate-180'}
            `}
            />
          </div>

          {showOptions &&
            createPortal(
              <div
                ref={dropdownRef}
                style={{
                  top: dropdownPosition.top,
                  left: dropdownPosition.left,
                  right: dropdownPosition.right,
                  minWidth: dropdownPosition.width,
                  maxWidth: '45vw',
                  width: 'fit-content'
                }}
                className={`
                fixed z-50 bg-white rounded-lg shadow-lg overflow-hidden p-6
                border border-hover-green
                w-full md:w-auto
              `}>
                <div className="flex flex-col items-center md:flex-row flex-wrap justify-center gap-6">
                  {options.map((option, index) => (
                    <div key={index}>
                      {'children' in option && option.children && !hideCardLabel && (
                        <div className={`text-center text-md mb-5 ${option.value === value ? 'text-hover-green' : ' text-dark-grey'}`}>
                          {option.label}
                        </div>
                      )}
                      <Card
                        variant="outlined"
                        selected={option.value === value}
                        className={`cursor-pointer flex flex-col justify-center items-center hover:bg-light-green ${cardClassName}`}
                        onClick={() => handleOptionClick(option)}>
                        <Card.Content className={`p-4 gap-3 ${cardContentClassName}`}>
                          {'children' in option && option.children ? (
                            <div className={`relative overflow-hidden ${option.value === value ? 'text-hover-green' : ' text-dark-grey'}`}>
                              {option.children}
                            </div>
                          ) : (
                            <div className="h-4">
                              <div className={`text-center text-md ${option.value === value ? 'text-hover-green' : ' text-dark-grey'}`}>
                                {option.label}
                              </div>
                            </div>
                          )}
                        </Card.Content>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>,
              document.body
            )}
        </div>
      ) : (
        <div className="relative" onClick={toggleDropdown}>
          <div className="relative flex items-center">
            {startIcon && <div className="absolute left-4">{startIcon}</div>}
            <input
              ref={inputRef}
              type="text"
              value={searchable ? searchText : displayValue}
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
              placeholder={!showLabel ? label : ''}
              className={`
                ${inputClassName}
                h-[56px]
                ${startIcon ? 'pl-12' : ''}
                ${endIcon ? 'pr-12' : ''}
              `}
              {...props}
            />
            <div className="absolute right-4 flex items-center gap-2 pointer-events-none">
              <span
                className={`w-4 h-4 ${
                  !props.disabled ? 'text-dark-grey' : 'text-light-grey'
                } transition-transform duration-200 inline-block ${showOptions ? 'rotate-180' : ''}`}>
                <ChevronDown className="w-4 h-4" />
              </span>
              {endIcon && <div className="flex items-center">{endIcon}</div>}
            </div>
          </div>

          {label && <label className={labelClassName}>{label}</label>}
          {typeof error === 'string' && <p className="mt-1 text-xs text-red-500">{error}</p>}

          {showOptions &&
            createPortal(
              <div
                ref={dropdownRef}
                className={`
                  fixed z-50 
                  bg-white 
                  border border-hover-green 
                  rounded-[4px]
                  shadow-lg 
                  max-h-48
                  overflow-y-auto
                  w-full
                `}
                style={{
                  width: dropdownPosition.width,
                  top: dropdownPosition.top,
                  left: dropdownPosition.left,
                  right: dropdownPosition.right
                }}>
                {filteredOptions.length === 0 ? (
                  <div className="px-4 py-2 text-light-blackish">No Options</div>
                ) : (
                  filteredOptions.map((option, index) => (
                    <div
                      key={index}
                      data-value={option.value}
                      className={`px-4 py-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ${
                        option.value === value ? 'bg-accent-grey' : ''
                      } ${option.label === 'No Options' ? 'cursor-default text-light-blackish' : ''} ${
                        'className' in option ? option.className : 'font-public-sans'
                      }`}
                      onMouseDown={() => option.label !== 'No Options' && handleOptionClick(option)}>
                      {'children' in option ? (
                        <div className="flex items-center">
                          {option.children}
                          {option.label}
                        </div>
                      ) : (
                        option.label
                      )}
                    </div>
                  ))
                )}
              </div>,
              document.body
            )}
        </div>
      )}
    </div>
  );
};
